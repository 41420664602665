import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';

import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ENUM_ICON } from 'enums/icons';
import { Icon } from 'components/icon';
import { useAppSelector } from 'store/hooks';
import { PageMainLabel, LoadingIcon } from 'components/common';
import { causesState } from 'store/causes/causes.slice';

import { tryParseInt } from 'utils/utils';
import { MAIN_CONTAINER_WIDTH } from 'styles/constants';
import { selectedCharitiesState } from 'store/charities/charities.slice';
import ImpactCarousel from 'components/impactCarousel';
import { getStripePaymentEmbedded } from 'store/auth/auth.services';
import { routes } from 'routes/routes';
import { CharityTypes } from 'store/causes/types';
import { CenteredWrapper, ActionItemsWrapper, IconWrapper, AmountBox } from './styles';

export const SubscriptionPage = () => {
   const navigate = useNavigate();
   const { selectedCausesList } = useAppSelector(causesState);
   const { selectedCharities } = useAppSelector(selectedCharitiesState);
   const startAmount = tryParseInt(process.env.REACT_APP_START_AMOUNT, 10);
   const minAmount = tryParseInt(process.env.REACT_APP_MIN_AMOUNT, 5);
   const changeAmount = tryParseInt(process.env.REACT_APP_CHANGE_AMOUNT, 5);
   const maxAmount = tryParseInt(process.env.REACT_APP_MAX_AMOUNT, 3000);
   const { t } = useTranslation();
   const messages: string[] = t('fundYourPortfolio.bullet', { returnObjects: true, components: { 1: <br /> } });

   const [amount, setAmount] = useState(startAmount);
   const [loading, setLoading] = useState(false);

   const handleSubmit = async () => {
      setLoading(true);
      analytics?.track('begin_checkout', {
         causes: `[${selectedCausesList.map((cause) => cause.id).join()}]`,
         value: amount,
         currency: 'USD',
      });
      const cs = await getStripePaymentEmbedded(amount, 0);
      navigate(routes().payment, { state: { cs } });
   };

   const getImactCarouselData = (userCharities: CharityTypes[]) => {
      const charities = [...userCharities].sort((a, b) => {
         if (a.indicatorPrice && b.indicatorPrice) {
            return Number(a.indicatorPrice) - Number(b.indicatorPrice);
         }
         return 0;
      });
      const lastCharity = charities.length > 3 ? 3 : charities.length;
      return charities.slice(0, lastCharity);
   };

   const handleChaiClick = () => {
      const chaiValue = 18;
      if (amount < chaiValue) {
         setAmount(chaiValue);
      } else {
         const numOfChais = Math.floor(amount / chaiValue);
         setAmount((numOfChais + 1) * chaiValue);
      }
   };

   const changeAmountButtonClick = (increase: boolean) => {
      let multi = Math.floor(amount / changeAmount);
      // eslint-disable-next-line no-plusplus
      increase ? multi++ : multi--;
      let finalValue = multi * changeAmount;
      if (finalValue < minAmount) {
         finalValue = minAmount;
      } else if (finalValue > maxAmount) {
         finalValue = maxAmount;
      }
      setAmount(finalValue);
   };

   useEffect(() => {
      document.title = `Subscription | ${t('siteTitleName')}`;
   }, []);

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            gap: { sm: '30px' },
            justifyContent: 'start',
            alignItems: 'center',
         }}
      >
         <Box sx={{ width: '100%' }}>
            <Box
               paddingBottom='50px'
               component='div'
               width='100%'
               display='flex'
               flexDirection='column'
               alignItems='center'
            >
               <Box sx={{ maxWidth: MAIN_CONTAINER_WIDTH, width: '100%', px: '20px' }}>
                  <PageMainLabel>
                     <Trans i18nKey='fundYourPortfolio.heading' components={{ 1: <br /> }} />
                  </PageMainLabel>
               </Box>

               <CenteredWrapper>
                  <ActionItemsWrapper sx={{ maxWidth: MAIN_CONTAINER_WIDTH, width: '100%', px: '20px' }} mx='auto'>
                     <IconWrapper
                        onClick={() => {
                           analytics?.track('amount_change', {
                              action: 'minus',
                              amount: amount - changeAmount,
                           });
                           changeAmountButtonClick(false);
                        }}
                        disabled={amount === minAmount}
                     >
                        <Icon name={ENUM_ICON.MINUS} />
                     </IconWrapper>
                     <AmountBox>
                        <Typography component='h6' className='SubscriptionAmount'>
                           ${amount}
                        </Typography>
                     </AmountBox>
                     <IconWrapper
                        onClick={() => {
                           analytics?.track('amount_change', {
                              action: 'plus',
                              amount: amount + changeAmount,
                           });

                           changeAmountButtonClick(true);
                        }}
                        disabled={amount === maxAmount}
                     >
                        <Icon name={ENUM_ICON.PLUS} />
                     </IconWrapper>
                  </ActionItemsWrapper>
                  <ImpactCarousel amount={amount} charities={getImactCarouselData(selectedCharities)} />
                  {t('fundYourPortfolio.textRow') !== '' ? (
                     <Box sx={{ maxWidth: MAIN_CONTAINER_WIDTH, width: '100%', px: '20px' }}>
                        <Box
                           onClick={handleChaiClick}
                           sx={{
                              color: 'rgba(71, 119, 241, 1)',
                              textDecorationLine: 'underline',
                              textAlign: 'center',
                              fontWeight: '700',
                              fontSize: '16px',
                              cursor: 'pointer',
                           }}
                        >
                           {t('fundYourPortfolio.textRow')}
                        </Box>
                     </Box>
                  ) : null}

                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        mt: '30px',
                        mb: { xs: '20px', md: '30px' },
                     }}
                  >
                     <Box sx={{ maxWidth: MAIN_CONTAINER_WIDTH, width: '100%', px: '20px' }}>
                        <Button onClick={handleSubmit} variant='primaryBtn'>
                           <Typography>{loading ? <LoadingIcon /> : `${t('fundYourPortfolio.buttonCTA')}`}</Typography>
                        </Button>
                     </Box>
                  </Box>
                  <Box
                     sx={{ maxWidth: MAIN_CONTAINER_WIDTH, width: '100%', px: '20px' }}
                     display='flex'
                     flexDirection='column'
                     gap={1}
                     maxWidth={{ sx: 'max-content' }}
                     mx={{ xs: 'auto' }}
                  >
                     {Array.isArray(messages) &&
                        messages
                           .filter((e) => e) // filter out empty strings
                           .map((text) => {
                              return <Typography key={text}>&bull; {text}</Typography>;
                           })}
                  </Box>
               </CenteredWrapper>
            </Box>
         </Box>
      </Box>
   );
};
