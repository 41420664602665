import React from 'react';

const AppleIcon = () => {
   return (
      <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path
            d='M12.1734 7.22357C11.2974 7.22357 9.94138 6.22757 8.51338 6.26357C6.62938 6.28757 4.90138 7.35556 3.92938 9.04756C1.97338 12.4436 3.42538 17.4596 5.33338 20.2196C6.26938 21.5636 7.37338 23.0756 8.83738 23.0276C10.2414 22.9676 10.7694 22.1156 12.4734 22.1156C14.1654 22.1156 14.6454 23.0276 16.1334 22.9916C17.6454 22.9676 18.6054 21.6236 19.5294 20.2676C20.5974 18.7076 21.0414 17.1956 21.0654 17.1116C21.0294 17.0996 18.1254 15.9836 18.0894 12.6236C18.0654 9.81556 20.3814 8.47157 20.4894 8.41157C19.1694 6.47957 17.1414 6.26357 16.4334 6.21557C14.5854 6.07157 13.0374 7.22357 12.1734 7.22357ZM15.2934 4.39157C16.0734 3.45557 16.5894 2.14757 16.4454 0.851562C15.3294 0.899563 13.9854 1.59557 13.1814 2.53157C12.4614 3.35957 11.8374 4.69157 12.0054 5.96357C13.2414 6.05957 14.5134 5.32757 15.2934 4.39157Z'
            fill='#333333'
         />
      </svg>
   );
};

export default AppleIcon;
