import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Typography, useMediaQuery, Button, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { routes } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { causesState, fetchSelectedCharities } from 'store/causes/causes.slice';
import { BackIconButton, PageMainLabel } from 'components/common';

import { authState } from 'store/auth/auth.slice';
import { MAIN_CONTAINER_WIDTH } from 'styles/constants';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';
import { SelectedCHaritiesByCauseType } from 'wrappers/types';
import { CauseTypes, CharityTypes } from 'store/causes/types';
import { setSelectedCharities } from 'store/causes/causes.services';
import { handleOnClickCharityCheckbox, clearSelectedCharities } from 'store/charities/charities.slice';
import {
   CauseTitle,
   CauseBoxItem,
   CauseTitleWrapper,
   CharitiesWrapper,
   CharityItemBox,
   CharitiesSelected,
} from './styles';

export const PortfolioPage = () => {
   const isWideScreen = useMediaQuery('(min-width:1134px)');
   const isMobile = useMediaQuery('(max-width:600px)');
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { t } = useTranslation();

   const { charityList, selectedCausesList } = useAppSelector(causesState);
   const { loggedUserData } = useAppSelector(authState);
   const [hasSubscription, setHasSubscription] = React.useState<boolean | undefined>(false);
   const [selectedCharitiesByCause, setSelectedCharitiesByCause] = useState<SelectedCHaritiesByCauseType>({});
   const [selectedCharitiesIds, setSelectedCharitiesIds] = useState<number[]>([]);
   const [charityModalData, setCharityModalData] = useState<CharityTypes | null>(null);

   const handleCharityClick = (charity: CharityTypes) => {
      if (!isMobile) {
         setCharityModalData(charity);
      } else {
         navigate(routes().charity.replace(':id', charity.id.toString()));
      }
   };

   const selectAll = () => {
      dispatch(clearSelectedCharities());
      const charitiesIds = new Set<number>();
      const preparedCharities: SelectedCHaritiesByCauseType = {};

      if (selectedCausesList.length) {
         selectedCausesList.forEach((cause) => {
            preparedCharities[`${cause.id}`] = [];
            charityList.forEach((charity) => {
               if (charity.causes.some((c) => c.id === cause.id)) {
                  preparedCharities[`${cause.id}`].push(charity);
                  charitiesIds.add(charity.id);
                  dispatch(handleOnClickCharityCheckbox(charity));
               }
            });
         });
      } else {
         const causes = new Set<CauseTypes>();
         charityList.forEach((ch) => causes.add(ch.causes[0]));
         causes.forEach((cause) => {
            const charitiesByCause = charityList.filter((ch) =>
               ch.causes.some((parentCause) => parentCause.id === cause.id)
            );
            charitiesByCause.forEach((ch) => charitiesIds.add(ch.id));
            preparedCharities[`${cause.id}`] = charitiesByCause;
         });
      }

      const charitiesIdsArray = Array.from(charitiesIds);

      setSelectedCharitiesIds(charitiesIdsArray);
      setSelectedCharitiesByCause(preparedCharities);
   };

   const handleSelectCharity = (event: React.ChangeEvent<HTMLInputElement>, causeId: number, charity: CharityTypes) => {
      const isChecked = event.target.checked;
      dispatch(handleOnClickCharityCheckbox(charity));
      if (isChecked) {
         setSelectedCharitiesByCause((prev) => {
            const newState = { ...prev };
            if (!newState[`${causeId}`]) newState[`${causeId}`] = [];
            newState[`${causeId}`].push(charity);
            return newState;
         });
         setSelectedCharitiesIds((prev) => [...prev, charity.id]);
      } else {
         setSelectedCharitiesByCause((prev) => {
            const newState = { ...prev };
            newState[`${causeId}`] = newState[`${causeId}`]?.filter((ch) => ch.id !== charity.id);
            return newState;
         });
         setSelectedCharitiesIds((prev) => [...prev].filter((ch) => ch !== charity.id));
      }
   };

   const getCharitiesSelectedText = (amount: number) => {
      if (amount === 1) return '1 charity selected';
      return `${amount} charities selected`;
   };

   const displayedCauseTypes = new Set();

   const maxCharitiesContainerWidth = charityList.length === 3 || !isWideScreen ? MAIN_CONTAINER_WIDTH : '1130px';
   const charitiesContainerFlexDirection = charityList.length === 3 || !isWideScreen ? 'column' : 'row';

   useEffect(() => {
      setHasSubscription(loggedUserData?.subscription?.status === 'active');
   }, [loggedUserData]);

   useEffect(() => {
      if (charityList?.length === 0) {
         dispatch(fetchSelectedCharities());
      }
      document.title = `Portfolio | ${t('siteTitleName')}`;
   }, []);

   const differentButtonAction = async () => {
      await setSelectedCharities(selectedCharitiesIds);
      if (hasSubscription) {
         navigate(routes().myProfile);
      } else {
         navigate(routes().subscription);
      }
   };

   const generateNextBtnLabel = () => {
      if (hasSubscription) {
         return 'Save';
      }
      return t('givingPortfolio.buttonCTA-WithoutArrow');
   };

   useEffect(() => {
      if (charityList && charityList.length > 0) {
         charityList.forEach((charity) => {
            new Image().src = charity.image;
         });
         selectAll();
      }
   }, [charityList]);

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            mx: 'auto',
            gap: { sm: '20px' },
            justifyContent: { xs: 'space-between', sm: 'start' },
         }}
      >
         <Box
            component='div'
            display='flex'
            flexDirection='column'
            alignItems='center'
            sx={{
               pb: {
                  xs: 2.5,
                  sm: 0,
               },
            }}
         >
            <PageMainLabel sx={{ maxWidth: MAIN_CONTAINER_WIDTH, px: '20px', marginBottom: '20px' }}>
               {t('givingPortfolio.heading')}
            </PageMainLabel>
            <Box
               component='div'
               display='flex'
               gap='20px'
               alignItems='center'
               justifyContent='center'
               flexDirection={charitiesContainerFlexDirection}
               paddingX='20px'
               sx={{ maxWidth: maxCharitiesContainerWidth, width: '100%' }}
            >
               {charityList.length > 0 &&
                  charityList.map((charity) => {
                     if (charity.causes.length > 0) {
                        for (const cause of charity.causes) {
                           if (!displayedCauseTypes.has(cause.title)) {
                              displayedCauseTypes.add(cause.title);
                              return (
                                 <CauseBoxItem key={cause.id}>
                                    <CauseTitleWrapper>
                                       <Box>
                                          <CauseTitle>{cause.title}</CauseTitle>
                                          <CharitiesSelected>
                                             {getCharitiesSelectedText(
                                                selectedCharitiesByCause[`${cause.id}`]?.length ?? 0
                                             )}
                                          </CharitiesSelected>
                                       </Box>
                                       <ReactSVG
                                          src={`data:image/svg+xml;utf8,${encodeURIComponent(cause.impactImage)}`}
                                          beforeInjection={(svg) => {
                                             svg.querySelectorAll('path').forEach((path) => {
                                                path.setAttribute('fill', '#333333');
                                             });
                                             svg.setAttribute('width', '40');
                                             svg.setAttribute('height', '40');
                                          }}
                                          style={{ width: '40px', height: '40px%' }}
                                       />
                                    </CauseTitleWrapper>
                                    <CharitiesWrapper>
                                       {charityList
                                          .filter((ch) => ch.causes.some((parentCause) => parentCause.id === cause.id))
                                          .map((ch) => (
                                             <CharityItemBox
                                                sx={{
                                                   cursor: 'pointer',
                                                   display: 'flex',
                                                   alignItems: 'center',
                                                   width: '100%',
                                                   height: 'fit-content',
                                                   px: '16px',
                                                   py: '10px',
                                                   boxSizing: 'border-box',
                                                   borderBottom: '1px solid #0000001A',
                                                }}
                                                key={ch.id}
                                             >
                                                <Checkbox
                                                   sx={{
                                                      p: 0,
                                                      width: '24px',
                                                      height: '24px',
                                                      color: '#F55D66',
                                                      '&.Mui-checked': {
                                                         color: '#F55D66',
                                                      },
                                                   }}
                                                   checked={selectedCharitiesIds.includes(ch.id)}
                                                   onChange={(e) => handleSelectCharity(e, cause.id, ch)}
                                                />
                                                <Box
                                                   sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      width: '100%',
                                                   }}
                                                   onClick={() => {
                                                      analytics?.track('view_charity', {
                                                         charity_id: ch.id,
                                                         charity_name: ch.title,
                                                      });
                                                      handleCharityClick(ch);
                                                   }}
                                                >
                                                   <Box
                                                      sx={{
                                                         width: '50px',
                                                         height: '50px',
                                                         border: '1px solid #E6E6E6',
                                                         overflow: 'hidden',
                                                         mx: '8px',
                                                      }}
                                                   >
                                                      <img alt={ch.title} src={ch.icon} height='100%' width='100%' />
                                                   </Box>
                                                   <Box
                                                      sx={{
                                                         mr: 'auto',
                                                      }}
                                                   >
                                                      <Typography
                                                         sx={{ fontSize: '14px', lineHeight: '17px', fontWeight: 700 }}
                                                      >
                                                         {ch.title}
                                                      </Typography>
                                                      <Typography
                                                         sx={{
                                                            fontSize: '12px',
                                                            lineHeight: '14px',
                                                            fontWeight: 500,
                                                            color: '#999999',
                                                         }}
                                                      >
                                                         {ch.shortDescription}
                                                      </Typography>
                                                   </Box>
                                                   <DynamicGoogleIcon iconName='chevron_forward' />
                                                </Box>
                                             </CharityItemBox>
                                          ))}
                                    </CharitiesWrapper>
                                 </CauseBoxItem>
                              );
                           }
                        }
                     }
                     return null;
                  })}
            </Box>
         </Box>

         <Box
            sx={{
               display: 'flex',
               justifyContent: 'center',
               width: '100%',
               paddingBottom: '40px',
               px: '20px',
            }}
         >
            <Button
               disabled={selectedCharitiesIds.length === 0}
               onClick={differentButtonAction}
               sx={{ maxWidth: '350px' }}
               variant='primaryBtn'
            >
               <Typography>{generateNextBtnLabel()}</Typography>
            </Button>
         </Box>
         <Modal open={charityModalData !== null} onClose={() => setCharityModalData(null)}>
            <Box
               sx={{
                  width: '480px',
                  height: 'auto',
                  borderRadius: '6px',
                  overflow: 'hidden',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#ffffff',
               }}
            >
               <BackIconButton
                  sx={{
                     position: 'absolute',
                     top: '10px',
                     right: '10px',
                     zIndex: '10000',
                  }}
                  onClick={() => {
                     setCharityModalData(null);
                  }}
               >
                  <DynamicGoogleIcon iconColor='#999999' iconName='close' />
               </BackIconButton>

               {/* Charity icon */}
               <Box
                  sx={{
                     position: 'absolute',
                     width: 80,
                     height: 80,
                     top: 190,
                     left: 20,
                     borderRadius: '6px',
                     overflow: 'hidden',
                     border: '1px solid #999999',
                     boxSizing: 'border-box',
                  }}
               >
                  <img
                     style={{
                        width: '100%',
                        height: 'auto',
                     }}
                     src={charityModalData?.icon}
                     alt=''
                  />
               </Box>

               {/* Charity image */}
               <Box
                  sx={{
                     width: '100%',
                     maxHeight: '210px',
                     overflow: 'hidden',
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                  }}
               >
                  <img
                     style={{
                        width: '100%',
                        height: 'auto',
                     }}
                     src={charityModalData?.image}
                     alt=''
                  />
               </Box>

               <Box
                  sx={{
                     px: '20px',
                     marginTop: '70px',
                  }}
               >
                  <Box
                     sx={{
                        marginBottom: '20px',
                     }}
                  >
                     <Typography
                        sx={{
                           fontSize: '20px',
                           lineHeight: '24px',
                           fontWeight: 700,
                        }}
                     >
                        {charityModalData?.title}
                     </Typography>
                     <Typography
                        sx={{
                           color: '#666666',
                           fontSize: '16px',
                           lineHeight: '19px',
                           fontWeight: 500,
                        }}
                     >
                        {charityModalData?.shortDescription}
                     </Typography>
                  </Box>

                  <Typography
                     sx={{
                        color: '#666666',
                        fontSize: '16px',
                        lineHeight: '19px',
                        fontWeight: 500,
                     }}
                  >
                     {charityModalData?.description}
                  </Typography>
               </Box>
               <Button
                  onClick={() => {
                     setCharityModalData(null);
                  }}
                  sx={{ width: '200px', margin: '20px' }}
                  variant='primaryBtn'
               >
                  <Typography>{t('model.ButtonCTA')}</Typography>
               </Button>
            </Box>
         </Modal>
      </Box>
   );
};
