import React from 'react';
import { Button, Typography } from '@mui/material';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';

interface ILoginWithEmailButtonProps {
   handleOnClick: () => void;
   size?: 'small';
}

const LoginWithEmail: React.FC<ILoginWithEmailButtonProps> = ({ handleOnClick, size }) => {
   return (
      <Button
         onClick={handleOnClick}
         sx={{
            mt: '10px',
         }}
         variant={size === 'small' ? 'loginBtn-small' : 'loginBtn'}
      >
         <DynamicGoogleIcon iconName='mail' />
         <Typography sx={{ ml: '5px' }}>Continue with Email</Typography>
      </Button>
   );
};

export default LoginWithEmail;
