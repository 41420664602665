import { Box } from '@mui/material';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { CenteredWrapper } from 'pages/welcome/styles';

const PaymentPage = () => {
   const location = useLocation();
   const { cs: clientSecret } = location.state || {};
   const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
   const stripePromise = stripeKey && loadStripe(stripeKey);
   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            gap: { sm: '30px' },
            justifyContent: 'start',
            alignItems: 'center',
         }}
      >
         <Box sx={{ width: '100%' }}>
            <Box component='div' width='100%' display='flex' flexDirection='column' alignItems='center'>
               <CenteredWrapper id='checkout'>
                  {clientSecret && stripePromise && (
                     <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
                        <EmbeddedCheckout />
                     </EmbeddedCheckoutProvider>
                  )}
               </CenteredWrapper>
            </Box>
         </Box>
      </Box>
   );
};

export default PaymentPage;
