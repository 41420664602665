import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import { Trans, useTranslation } from 'react-i18next';
import { PageMainLabel, ContentWrapper, BottomContentBox, RegLinkText } from 'components/common';
import { routes } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addEmail, addName, authState, createAnonymousSignup, sendMagicLink } from 'store/auth/auth.slice';
import { commonState } from 'store/common/common.slice';
import {
   handleSelectCause,
   handleSendSelectedCauses,
   fetchCharitiesBySelectedCauses,
   causesState,
} from 'store/causes/causes.slice';
import { CauseTypes } from 'store/causes/types';
import useRedirectAfterLogin from 'hooks/useRedirectAfterLogin';
import { updateUser } from 'store/auth/auth.services';

export const NamePage = () => {
   const navigate = useNavigate();
   const location = useLocation();
   const { updateName } = location.state || {};
   const dispatch = useAppDispatch();
   const inputRef = useRef<HTMLDivElement | null>(null);
   const { signupData, loggedUser } = useAppSelector(authState);
   const { project } = useAppSelector(commonState);
   const [name, setName] = useState(signupData.firstName ?? '');
   const [error, setError] = useState(false);
   const [submitting, setSubmitting] = useState(false);
   const { selectedCausesList } = useAppSelector(causesState);
   const { navigateUser } = useRedirectAfterLogin();

   const { t } = useTranslation();

   const mainLabelText = t('loginEmail.name.heading');
   const isButtonDisabled = submitting || error || name.length === 0;
   const inputPlaceholder = 'Your first name';
   const buttonText = 'Create account';
   const errorMessage = t('signUp.nameErrorMessage');

   const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newName = e.target.value;
      setName(newName);
      dispatch(addName(newName));
   };

   const handleNext = async () => {
      if (submitting) return;
      if (name.length >= 3) {
         setError(false);
         setSubmitting(true);
         dispatch(addName(name));

         if (updateName) {
            const response = await updateUser({ firstName: name });

            if (response.status === 200 && response.data) navigateUser(response.data);
         } else {
            // standart flow
            dispatch(createAnonymousSignup())
               .then(({ payload }) => {
                  if (payload.response && payload.response?.status === 409) {
                     dispatch(sendMagicLink()).then((res) => {
                        navigate(routes().checkYourEmail);
                     });
                  } else if (payload.user) {
                     if (payload.user.details.creatorCoupon) {
                        navigate(`${routes().creatorFlow.base}/${routes().creatorFlow.creatorImpact}`);
                     } else if (project === 'helene') {
                        // Set cuases hard coded
                        const causeHeleneCause: CauseTypes = {
                           id: 11,
                           title: 'Helene',
                           description: '',
                           active: true,
                           icon: '',
                           shortDescription: '',
                           order: 1,
                           impactImage: '',
                           impactHeader: '',
                           impactBackground: '',
                        };
                        dispatch(handleSelectCause(causeHeleneCause));
                        const causeHeleneCause2: CauseTypes = {
                           ...causeHeleneCause,
                           id: 8,
                        };
                        dispatch(handleSelectCause(causeHeleneCause2));
                        const causeHeleneCause3: CauseTypes = {
                           ...causeHeleneCause,
                           id: 7,
                        };
                        dispatch(handleSelectCause(causeHeleneCause3));

                        dispatch(handleSendSelectedCauses()).then(() => {
                           dispatch(fetchCharitiesBySelectedCauses()).then(() => {
                              localStorage.setItem('selectedCauses', JSON.stringify(selectedCausesList));
                              navigate(routes().generatePage);
                           });
                        });
                        navigate(routes().generatePage);
                     } else {
                        navigateUser(payload.user);
                     }
                  } else {
                     dispatch(addEmail(''));
                     dispatch(addName(''));
                  }
               })
               .finally(() => {
                  setSubmitting(false);
               });
         }
      } else {
         setError(true);
      }
   };

   useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
         if (event.key === 'Enter') {
            event.preventDefault();
            handleNext();
         }
      };
      if (inputRef && inputRef.current) {
         inputRef.current.addEventListener('keypress', handleKeyPress);
      }
      analytics.page('signup_name');

      return () => {
         if (inputRef && inputRef.current) {
            inputRef.current.removeEventListener('keypress', handleKeyPress);
         }
      };
   }, []);

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: { xs: '20px', sm: 0 },
            mb: { xs: '52px', sm: 0 },
         }}
      >
         <ContentWrapper
            sx={{
               maxWidth: '390px',
               justifyContent: 'start',
            }}
         >
            <Box component='div' width='100%' display='flex' flexDirection='column' mb='30px'>
               <PageMainLabel mb='20px'>{mainLabelText}</PageMainLabel>

               <TextField
                  fullWidth
                  required
                  className='CommonInput'
                  value={name}
                  type='text'
                  ref={inputRef}
                  onFocus={() => setError(false)}
                  onChange={handleInputOnChange}
                  error={error}
                  placeholder={inputPlaceholder}
               />
               {error && (
                  <Typography sx={{ mt: '4px', textAlign: { sm: 'center' }, color: '#F55D66' }}>
                     {errorMessage}
                  </Typography>
               )}
            </Box>

            <BottomContentBox isFocused={false} focusedValue='calc(-100% + 642px)'>
               <Button disabled={isButtonDisabled} onClick={handleNext} variant='primaryBtn'>
                  <Typography>{buttonText}</Typography>
               </Button>
               {project === 'helene' ? (
                  <RegLinkText sx={{ mt: { xs: '28px', sm: '28px' } }}>
                     {t('clickTC')}
                     <Link to={`${process.env.REACT_APP_MAIN_BASE_URL}/tc/`} target='_blank'>
                        <span style={{ minWidth: 'max-content' }}>{t('terms')}</span>
                     </Link>{' '}
                     and{' '}
                     <Link to={`${process.env.REACT_APP_MAIN_BASE_URL}/privacy-policy/`} target='_blank'>
                        <span style={{ minWidth: 'max-content' }}>{t('privacyPolicy')}</span>
                     </Link>
                     <div>
                        <Trans
                           i18nKey='tcSupport'
                           // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label, react/self-closing-comp
                           components={{ mailLink: <a href='mailto:support@proisrael.co'></a> }}
                        />
                     </div>
                  </RegLinkText>
               ) : (
                  ''
               )}
            </BottomContentBox>
         </ContentWrapper>
      </Box>
   );
};
