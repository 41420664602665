import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentWrapper } from 'components/common';
import { authState } from 'store/auth/auth.slice';
import { useAppSelector } from 'store/hooks';
import { routes } from 'routes/routes';
import api from 'api';

interface IOptionBoxProps {
   text: string;
   onClick: () => void;
}

type QuestionData = {
   question_name: string;
   question_answer: string;
};

const OptionBox: React.FC<IOptionBoxProps> = ({ text, onClick }) => {
   return (
      <Button
         onClick={onClick}
         sx={{
            borderRadius: '8px',
            justifyContent: 'start',
            width: '100%',
            backgroundColor: '#ffffff',
            px: '16px',
            py: '21px',
            boxSizing: 'border-box',
            border: '1px solid #E5E5E5',
            marginBottom: '10px',
         }}
      >
         <Typography
            sx={{
               textTransform: 'none',
               fontFamily: 'Figtree',
               fontWeight: 600,
               fontSize: 18,
               lineHeight: '22px',
            }}
         >
            {text}
         </Typography>
      </Button>
   );
};

const MarketingSurvey = () => {
   const navigate = useNavigate();
   const { loggedUserData } = useAppSelector(authState);
   const questions = [
      'Over the past year, how often have you donated to charity?',
      'If you found a cause that aligns with your values, would you consider making a donation today?',
   ];
   const answers: { option1: string; option2: string; option3: string }[] = [
      {
         option1: 'Regularly (at least once a month)',
         option2: 'Occasionally (a few times a year)',
         option3: 'Not in the past year',
      },
      {
         option1: "Yes, I'm ready to help",
         option2: 'I might need more information first',
         option3: 'Not at this time',
      },
   ];
   const initialData = [
      { question_name: 'onboarding_filtering_frequency', question_answer: '' },
      { question_name: 'onboarding_filtering_willingness', question_answer: '' },
   ];

   const sendDynamicData = async (dynamicData: QuestionData[]) => {
      try {
         await api.patch('/v1/users/me/details/dynamic', { dynamic: { onboarding_question: dynamicData } });
      } catch (error) {
         console.log(error);
      }
   };

   const [onboardingQuestionData, setOnboardingQuestionData] = React.useState<QuestionData[]>(initialData);
   const [step, setStep] = React.useState(0);
   const title = `Hi ${loggedUserData?.firstName}, thanks for choosing to do good today!`;
   const question = questions[step];

   const handleOnAnswerClick = (answer: number) => {
      setOnboardingQuestionData((prev) => {
         const newData = [...prev];
         newData[step].question_answer = answers[step][`option${answer}` as keyof (typeof answers)[0]];
         return newData;
      });
      if (step === 0) {
         setStep(1);
      }
   };

   useEffect(() => {
      const isReadyToPatch = onboardingQuestionData.every((q) => q.question_answer !== '');
      if (isReadyToPatch) {
         sendDynamicData(onboardingQuestionData);
         onboardingQuestionData.forEach((q) => {
            analytics?.track('onboarding_question', {
               question_name: q.question_name,
               question_answer: q.question_answer,
            });
         });
         navigate(routes().causes);
      }
   }, [onboardingQuestionData]);

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: { xs: '20px', sm: 0 },
            mb: { xs: '52px', sm: 0 },
         }}
      >
         <ContentWrapper
            sx={{
               maxWidth: '390px',
               justifyContent: 'start',
            }}
         >
            {step === 0 ? (
               <Box sx={{ mb: '20px' }}>
                  <Typography sx={{ fontFamily: 'Figtree', fontWeight: 700, fontSize: 24, lineHeight: '29px' }}>
                     {title}
                  </Typography>
               </Box>
            ) : null}
            <Box sx={{ mb: '20px' }}>
               <Typography sx={{ fontFamily: 'Figtree', fontWeight: 700, fontSize: 32, lineHeight: '38px' }}>
                  {question}
               </Typography>
            </Box>
            <Box flexDirection='column'>
               <OptionBox text={answers[step].option1} onClick={() => handleOnAnswerClick(1)} />
               <OptionBox text={answers[step].option2} onClick={() => handleOnAnswerClick(2)} />
               <OptionBox text={answers[step].option3} onClick={() => handleOnAnswerClick(3)} />
            </Box>
         </ContentWrapper>
      </Box>
   );
};

export default MarketingSurvey;
