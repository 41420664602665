import { Box, Typography } from '@mui/material';
import React from 'react';
import DynamicGoogleIcon from '../dynamic-icon/dynamicGoogleIcon';

interface IDataBoxProps {
   icon: string;
   number: number;
   text: string;
}

const DataBox: React.FC<IDataBoxProps> = ({ icon, number, text }) => {
   return (
      <Box
         sx={{
            width: '33%',
            backgroundColor: '#fff',
            padding: '10px',
            borderRadius: '4px',
            lineHeight: 1,
         }}
      >
         <DynamicGoogleIcon iconName={icon} />
         <Typography
            sx={{
               fontFamily: 'Inter',
               color: '#333',
               fontWeight: '700',
               fontSize: '26px',
               lineHeight: '30px',
            }}
         >
            {number}
         </Typography>
         <Typography
            sx={{
               fontFamily: 'Inter',
               color: '#999',
               fontSize: '12px',
               fontWeight: '500',
               lineHeight: '14.52px',
            }}
         >
            {text}
         </Typography>
      </Box>
   );
};

export default DataBox;
