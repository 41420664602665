import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useSearchParams, useLocation } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { routes } from 'routes/routes';

import { RegLinkText } from 'components/common';
import { authState } from 'store/auth/auth.slice';
import { MAIN_CONTAINER_WIDTH } from 'styles/constants';
import WelcomePageCells from 'components/welcomeCells/WelcomePageCells';
import LoginWithGoogle from 'components/loginButtons/LoginWithGoogle';
import LoginWithEmail from 'components/loginButtons/LoginWithEmail';
import { useLogout } from 'hooks/useLogout';
import LoginWithApple from 'components/loginButtons/LoginWithApple';
import useThemePage from 'hooks/useThemePage';
import Logo2 from 'components/Logo2';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';
import DataBox from 'components/welcomePageRenderSwitch/DataBox';
import RenderSwitch from 'components/welcomePageRenderSwitch/RenderSwitch';
import { ButtonsBox, PageMainLabel, PageSubLabel } from './styles';

export const WelcomePageV2 = () => {
   const { logout } = useLogout();
   // const { theme } = useThemePage();
   // const showAnimalsPage = theme === 'animals';
   const navigate = useNavigate();
   const { t } = useTranslation();
   const [searchParams] = useSearchParams();
   const { loggedUserData } = useAppSelector(authState);
   const [returnUser, setReturnUser] = useState(searchParams.get('back') === 'true');

   const dataBoxesArray: { icon: string; number: string; text: string }[] = t('animalsPage.cube', {
      returnObjects: true,
   });
   const filteredDataBoxesArray = dataBoxesArray.filter((o) => o);

   const handleSwitchUser = () => {
      setReturnUser(false);
      logout();
   };

   useEffect(() => {
      const loadAppleScript = () => {
         return new Promise<void>((resolve, reject) => {
            if (document.getElementById('apple-js')) {
               resolve(); // Avoid loading multiple times
               return;
            }

            const script = document.createElement('script');
            script.id = 'apple-js';
            script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
            script.async = true;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error('Apple JS SDK failed to load'));
            document.body.appendChild(script);
         });
      };

      const initializeAppleLogin = () => {
         if (window.AppleID) {
            const redirectURI = process.env.REACT_APP_BASE_URL ?? '';

            window.AppleID.auth.init({
               clientId: 'so.bono',
               scope: 'name email',
               redirectURI,
               usePopup: true,
            });
         }
      };

      loadAppleScript()
         .then(() => initializeAppleLogin())
         .catch((error) => console.error('Apple Login initialization failed:', error));

      document.title = t('siteTitleName');
   }, []);

   useEffect(() => {
      if (loggedUserData) {
         if (loggedUserData.subscription?.status === 'active') {
            navigate(routes().myProfile);
         } else {
            setReturnUser(true);
         }
      }
   }, [loggedUserData]);

   const handleNext = () => {
      if (loggedUserData) {
         navigate(`${routes().causes}${returnUser ? '?back=true' : ''}`);
      } else {
         navigate(routes().loginEmail);
      }
   };

   return <RenderSwitch returnUser={returnUser} handleSwitchUser={handleSwitchUser} handleNext={handleNext} />;
};
