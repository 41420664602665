import { useLocation } from 'react-router-dom';

const useThemePage = (): { theme: string | null } => {
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const theme = searchParams.get('theme');

   return { theme };
};

export default useThemePage;
