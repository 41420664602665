import React from 'react';
import { Trans } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { PageMainLabel, PageSubLabel, RegLinkText } from 'components/common';
import LoginWithApple from 'components/loginButtons/LoginWithApple';
import LoginWithEmail from 'components/loginButtons/LoginWithEmail';
import LoginWithGoogle from 'components/loginButtons/LoginWithGoogle';
import Logo2 from 'components/Logo2';
import useThemePage from 'hooks/useThemePage';
import { routes } from 'routes/routes';
import { authState } from 'store/auth/auth.slice';
import { useAppSelector } from 'store/hooks';
import WelcomePageCells from 'components/welcomeCells/WelcomePageCells';
import { ButtonsBox } from 'pages/welcome/styles';
import { MAIN_CONTAINER_WIDTH } from 'styles/constants';
import DataBox from './DataBox';

interface IRenderSwitchProps {
   returnUser: boolean;
   handleSwitchUser: () => void;
   handleNext: () => void;
}

const RenderSwitch: React.FC<IRenderSwitchProps> = ({ returnUser, handleSwitchUser, handleNext }) => {
   const { theme } = useThemePage();
   const navigate = useNavigate();
   const { loggedUserData } = useAppSelector(authState);

   const dataBoxesArray: { icon: string; number: string; text: string }[] = t('animalsPage.cube', {
      returnObjects: true,
   });
   const filteredDataBoxesArray = dataBoxesArray.filter((o) => o);

   if (theme === 'animals' && !returnUser)
      return (
         <Box
            sx={{
               flex: '1',
               display: 'flex',
               flexDirection: 'column',
            }}
         >
            <Box
               sx={{
                  flex: '1',
                  maxWidth: '1280px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: { xs: 'center', sm: 'end' },
                  marginX: { sm: 'auto' },
                  paddingRight: { sm: '100px' },
               }}
            >
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     width: '100%',
                     maxWidth: '350px',
                     height: 'fit-content',
                     backgroundColor: 'rgba(0, 0, 0, 0.2)', // 20% opacity black
                     backdropFilter: 'blur(10px)', // Adjust blur intensity
                     borderRadius: '8px',
                     border: '1px solid rgba(255, 255, 255, 0.20)', // 20% opacity white
                     margin: { xs: '20px', sm: '0' },
                     padding: '20px',
                  }}
               >
                  <Logo2 />
                  <Typography
                     sx={{
                        marginY: '10px',
                        fontSize: '32px',
                        lineHeight: '32px',
                        fontWeight: 700,
                        color: '#FFFFFF',
                     }}
                  >
                     {t('animalsPage.title')}
                  </Typography>
                  <Typography
                     sx={{
                        color: '#FFFFFF',
                        fontSize: '14px',
                        lineHeight: '17px',
                        fontWeight: 600,
                        marginBottom: '10px',
                     }}
                  >
                     {t('animalsPage.text')}
                  </Typography>
                  <Box sx={{ display: 'flex', marginBottom: '20px', columnGap: '6px', alignItems: 'stretch' }}>
                     {filteredDataBoxesArray.map((o) => (
                        <DataBox key={`${o.icon}-${o.number}`} icon={o.icon} number={Number(o.number)} text={o.text} />
                     ))}
                  </Box>
                  <LoginWithApple size='small' />
                  <LoginWithGoogle size='small' />
                  <LoginWithEmail size='small' handleOnClick={handleNext} />
                  <RegLinkText
                     sx={{
                        mt: '10px',
                        textAlign: 'center',
                        color: '#FFF',
                     }}
                  >
                     {t('clickTC')} <br />
                     <Link
                        style={{ textDecorationColor: '#FFF' }}
                        to={`${process.env.REACT_APP_MAIN_BASE_URL}/tc/`}
                        target='_blank'
                     >
                        <span style={{ minWidth: 'max-content', color: '#FFF' }}>{t('terms')}</span>
                     </Link>{' '}
                     and{' '}
                     <Link
                        style={{ textDecorationColor: '#FFF' }}
                        to={`${process.env.REACT_APP_MAIN_BASE_URL}/privacy-policy/`}
                        target='_blank'
                     >
                        <span style={{ minWidth: 'max-content', color: '#FFF' }}>{t('privacyPolicy')}</span>
                     </Link>
                  </RegLinkText>
               </Box>
            </Box>
         </Box>
      );

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: { xs: 'space-between', sm: 'start' },
            pb: 4,
         }}
      >
         <Box
            component='div'
            display='flex'
            flexDirection='column'
            sx={{
               width: {
                  xs: '100%',
                  sm: '100%',
                  textAlign: 'left',
               },
            }}
         >
            <Box
               width='100%'
               sx={{
                  margin: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  pt: { xs: '30px', sm: '0px' },
               }}
            >
               <PageMainLabel
                  maxWidth={MAIN_CONTAINER_WIDTH}
                  sx={{ paddingBottom: '10px', px: { xs: 2.5, sm: 0 }, width: '100%' }}
               >
                  {returnUser && loggedUserData
                     ? `${t('homePage.headingBack')} ${loggedUserData.firstName}`
                     : t('homePage.heading')}
               </PageMainLabel>
               <PageSubLabel
                  maxWidth={MAIN_CONTAINER_WIDTH}
                  sx={{ paddingBottom: ['25px', '30px', '30px'], color: '#666666', px: { xs: 2.5, sm: 0 } }}
               >
                  {returnUser && loggedUserData
                     ? `${t('homePage.subHeadingBack')} ${loggedUserData.email}`
                     : t('homePage.subHeading')}
               </PageSubLabel>
               <WelcomePageCells />
            </Box>
         </Box>
         <Box width='100%' sx={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonsBox sx={{ px: { xs: 2.5, sm: 0 } }} maxWidth={MAIN_CONTAINER_WIDTH} pt='30px'>
               {returnUser ? (
                  <Button onClick={() => navigate(routes().causes)} variant='primaryBtn'>
                     <Typography>Next</Typography>
                  </Button>
               ) : (
                  <>
                     <LoginWithApple />
                     <LoginWithGoogle />
                     <LoginWithEmail handleOnClick={handleNext} />
                  </>
               )}
               {returnUser ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '20px' }}>
                     <Typography
                        sx={{
                           fontFamily: 'Figtree',
                           fontSize: '16px',
                           lineHeight: '19px',
                           color: '#666666',
                        }}
                     >
                        {t('homePage.footerTextBack')}
                     </Typography>
                     <Button onClick={handleSwitchUser} sx={{ ':hover': { backgroundColor: 'transparent' } }}>
                        <Typography
                           sx={{
                              fontFamily: 'Figtree',
                              fontSize: '16px',
                              lineHeight: '19px',
                              color: '#666666',
                              textDecoration: 'underline',
                              textTransform: 'none',
                           }}
                        >
                           {t('homePage.switchAccount')}
                        </Typography>
                     </Button>
                  </Box>
               ) : (
                  <RegLinkText sx={{ mt: { xs: '28px', sm: '28px' } }}>
                     {t('clickTC')} <br />
                     <Link to={`${process.env.REACT_APP_MAIN_BASE_URL}/tc/`} target='_blank'>
                        <span style={{ minWidth: 'max-content' }}>{t('terms')}</span>
                     </Link>{' '}
                     and{' '}
                     <Link to={`${process.env.REACT_APP_MAIN_BASE_URL}/privacy-policy/`} target='_blank'>
                        <span style={{ minWidth: 'max-content' }}>{t('privacyPolicy')}</span>
                     </Link>
                     <div>
                        <Trans
                           i18nKey='tcSupport'
                           // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label, react/self-closing-comp
                           components={{ mailLink: <a href='mailto:support@proisrael.co'></a> }}
                        />
                     </div>
                  </RegLinkText>
               )}
            </ButtonsBox>
         </Box>
      </Box>
   );
};

export default RenderSwitch;
