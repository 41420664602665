import React from 'react';
import { Box, Button, Link, Modal, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import AppleIcon from 'components/loginIcons/AppleIcon';
import { loginUserWithApple } from 'store/auth/auth.slice';
import { useAppDispatch } from 'store/hooks';
import { AppleNameFormat, LoggedUserTypes } from 'store/auth/types';
import useRedirectAfterLogin from 'hooks/useRedirectAfterLogin';

interface ILoginWithAppleProps {
   size?: 'small';
}

const LoginWithApple: React.FC<ILoginWithAppleProps> = ({ size }) => {
   const { t } = useTranslation();
   const dispatch = useAppDispatch();
   const { navigateUser } = useRedirectAfterLogin();

   const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);

   const handleCloseModal = () => {
      setIsErrorModalOpen(false);
   };

   const handleSignIn = async () => {
      try {
         const data = await window.AppleID.auth.signIn();
         if (data?.authorization?.id_token) {
            const fetchedUser = data?.user;
            const fullName: AppleNameFormat = {
               familyName: null,
               givenName: null,
               middleName: null,
               namePrefix: null,
               nameSuffix: null,
               nickname: null,
            };
            if (fetchedUser) {
               if (fetchedUser.name?.lastName) fullName.familyName = fetchedUser.name?.lastName ?? null;
               if (fetchedUser.name?.firstName) fullName.givenName = fetchedUser.name?.firstName ?? null;
            }
            const response = await dispatch(loginUserWithApple({ token: data.authorization.id_token, fullName }));
            if (response.payload?.user) {
               const { user } = response.payload as LoggedUserTypes;
               navigateUser(user);
            } else {
               setIsErrorModalOpen(true);
            }
         }
      } catch (error: any) {
         console.log(error);
         if (error.error !== 'popup_closed_by_user') setIsErrorModalOpen(true);
      }
   };

   return (
      <>
         <Button sx={{ mb: '10px' }} onClick={handleSignIn} variant={size === 'small' ? 'loginBtn-small' : 'loginBtn'}>
            <AppleIcon />
            <Typography sx={{ ml: '5px' }}>Continue with Apple</Typography>
         </Button>
         {isErrorModalOpen && (
            <Modal
               open={isErrorModalOpen}
               onClose={handleCloseModal}
               aria-labelledby='modal-title'
               aria-describedby='modal-description'
            >
               <Box
                  sx={{
                     position: 'absolute',
                     top: '50%',
                     left: '50%',
                     transform: 'translate(-50%, -50%)',
                     width: 350,
                     bgcolor: 'background.paper',
                     px: 4,
                     py: 3,
                     textAlign: 'center',
                     borderRadius: '8px',
                     outline: 'none',
                  }}
               >
                  <Typography sx={{ my: 2, textAlign: 'center', fontWeight: '700', fontSize: '22px' }}>
                     {t('loginApple.modalTitle')}
                  </Typography>
                  <Typography
                     id='modal-description'
                     sx={{
                        textAlign: 'start',
                        fontWeight: '500',
                        fontSize: '16px',
                        lineHeight: '18px',
                        mb: 2,
                     }}
                  >
                     <Trans
                        i18nKey='loginApple.modalText'
                        components={{
                           1: <br />,
                           2: (
                              <Link style={{ textTransform: 'none' }} href='mailto:support@bono.so'>
                                 support@bono.so
                              </Link>
                           ),
                        }}
                     />
                  </Typography>
                  <Button variant='primaryBtn' onClick={handleCloseModal}>
                     <Typography>{t('loginApple.modalButtonText')}</Typography>
                  </Button>
               </Box>
            </Modal>
         )}
      </>
   );
};

export default LoginWithApple;
